import { render, staticRenderFns } from "./TemplateDefaultPage.vue?vue&type=template&id=ed19761a&scoped=true&"
import script from "./TemplateDefaultPage.vue?vue&type=script&lang=js&"
export * from "./TemplateDefaultPage.vue?vue&type=script&lang=js&"
import style0 from "./TemplateDefaultPage.vue?vue&type=style&index=0&id=ed19761a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed19761a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionsBaseSectionSelector: require('/app/components/sections/base/SectionSelector.vue').default})
